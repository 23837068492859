import { useEffect } from "react";

import CommentComp from "./Comment";
import { SectionTitle } from "./SectionTitle";
import { useSocket } from "../../hooks/useSocket";
import { NewComment } from "./NewComment";
import { ShowMoreComment } from "./Comment/ShowMoreComment";

const Comments = ({
  suitability,
  sendItemClick,
}: {
  suitability?: string;
  sendItemClick: (label: string) => void;
}) => {
  const {
    comments,
    handleLike,
    addNewMessages,
    isAddedNewMessage,
    getComments,
    isShowMore,
    showMoreComments,
  } = useSocket(suitability);

  useEffect(() => {
    getComments(suitability);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowMore = () => {
    showMoreComments();
    sendItemClick("show_more_comments");
  };

  return (
    <div className="pt-[18px] px-[18px] w-full flex flex-col">
      <SectionTitle />
      {isAddedNewMessage && <NewComment addNewMessages={addNewMessages} />}
      <div className="flex flex-col w-full gap-y-[18px] mt-[18px]">
        {comments?.map((comment) => (
          <CommentComp
            handleLike={handleLike}
            key={comment?.uuid}
            comment={comment}
          />
        ))}
        <ShowMoreComment show={isShowMore} handleShowMore={handleShowMore} />
      </div>
    </div>
  );
};

export default Comments;
